import { Link } from "gatsby";
import React from "react";
import Logo from 'assets/hunter-family-spending-planners-logo.svg';

export default function Header() {
    const liClasses = `flex-1 md:flex-none`
    const aClasses = `nav-link block md:mx-3 text-gray-700`
    return (
        <header className="w-full py-8 bg-green-500 shadow">
            <div className="mx-auto px-4 max-w-screen-xl flex justify-between items-end">
                <Link className="headerlogo" to="/">
                    <img src={Logo} alt="Hunter Family Spending Planners logo" width="351" height="44" />
                </Link>
                <ul className="nav font-medium bg-green-500 border-t-2 border-green-600
                    flex items-center text-black uppercase
                    fixed bottom-0 left-0 w-full z-50 text-center
                    md:relative md:flex-none md:w-1/2 md:justify-end md:border-t-0
                    md:text-lg">
                    <li className={liClasses}>
                        <Link className={aClasses} to="/about/">About</Link>
                    </li>
                    {/* <li className={liClasses}>
                        <Link className={aClasses} to="/#spending-plan">Spending plan</Link>
                    </li> */}
                    <li className={liClasses}>
                        <Link className={aClasses} to="/blog/">Blog</Link>
                    </li>
                    <li className={liClasses}>
                        <Link className={aClasses} to="/contact/">Contact</Link>
                    </li>
                </ul>
            </div>
        </header>
    );
}
