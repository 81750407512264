import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { StaticImage } from "gatsby-plugin-image"
import FacebookLogo from 'assets/fb.svg';

function getYear() {
    return new Date().getFullYear();
}

export default function Footer() {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            markdownRemark(frontmatter: {section: {eq: "contact"}}) {
                frontmatter {
                    address
                    phone
                    email
                }
            }
        }
    `)

    return (
        <div className="footer bg-green-800 text-green-100 pt-12 pb-6" id="contact">
            <div className="max-w-screen-xl mx-auto px-4 grid md:grid-cols-2">
                <div className="mb-12 md:mb-0 text-center md:text-right">
                    <OutboundLink href="https://www.facebook.com/hfsplanners/" target="_blank" rel="noopener noreferrer" className="inline-block mr-6">
                        <img src={FacebookLogo} alt="Facebook" width="50" height="50" />
                    </OutboundLink>
                    <OutboundLink href="https://spendingplannersinstitute.com/" target="_blank" rel="noopener noreferrer" className="inline-block">
                        <StaticImage
                            src="../assets/spending-planners-institute-certified-member.png"
                            alt="Spending Planners Institute - Certified Member"
                            layout="fixed"
                            formats={["auto","webp","avif"]}
                            width={165}
                            height={50}
                            />
                        
                    </OutboundLink>
                </div>
                <div className="text-sm md:order-first">
                    © Copyright {getYear()} Hunter Family Spending Planners. ABN:&nbsp;80&nbsp;613&nbsp;575&nbsp;189<br />Ph: {data.markdownRemark.frontmatter.phone}<br />
                    <Link to="/privacy-policy/" className="underline mr-6">Privacy Policy</Link> <OutboundLink href="https://www.sasha.net.au/" target="_blank" rel="noopener" title="Sasha the web developer" className="underline">Website by Sasha</OutboundLink>
                </div>
            </div>
        </div>
    );
}
